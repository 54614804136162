@import 'bootstrap/scss/mixins/breakpoints';

// list
ul {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }
}

// container
.container {
  max-width: $container-max-width;
  &._md {
    max-width: $container-md-max-width;
  }

  @include media-breakpoint-down(xl) {
    max-width: 100%;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

// z-index
@for $i from 1 through 9 {
  .z-index-#{$i} {
    z-index: $i !important;
  }
}

// width
@for $i from 1 through 9 {
  .w-#{$i*10} {
    width: #{$i * 10}#{'% !important'};
  }
}

// height
@for $i from 15 through 35 {
  .hx-#{$i*10} {
    height: #{$i * 10}#{'px !important'};
  }
}
