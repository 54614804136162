@import 'scss/variables';

.amp_loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, 0.6);
  .amp_loader_image {
    background-color: $primary;
    padding: $spacer;
    border-radius: $border-radius-lg;
    width: $spacer * 5;
    height: $spacer * 5;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
