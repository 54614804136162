@import 'scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

.amp_icon_card {
    background-color: $white;
    transition: $transition-base;
    &:hover {
        background-color: rgba($secondary, 0.1);
        border-color: $secondary !important;
    }
    .amp_card_logo_wrap {
        svg {
            width: 100%;
            height: $spacer * 3;
            @include media-breakpoint-down(lg) {
                height: $spacer * 2;
            }
            @include media-breakpoint-down(md) {
                max-width: 120px;
            }
        }
    }
    &.disabled {
        background-color: rgba($black, 0.03) !important;
    }
}