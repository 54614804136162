html {
  /* scrollbar style start */
  // For Firefox
  scrollbar-color: tint-color($primary, 70%);

  // For Chrome
  ::-webkit {
    &-scrollbar {
      width: $spacer * 0.75;
      height: $spacer * 0.75;

      &-track-piece {
        background-color: tint-color($primary, 70%);
      }

      &-thumb:vertical {
        height: $spacer * 2;
        background-color: $primary;
      }
    }
  }

  /* scrollbar style end */

  @include media-breakpoint-down(lg) {
    font-size: $font-size-base * 0.875;
  }
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  background: $white url(../../../public/assets/images/body_bg.png) no-repeat top right / 100% auto;
  color: $dark;
  transition: $transition-base;
  @include media-breakpoint-down(lg) {
    background-size: auto 100%;
  }
}

:focus {
  outline: none;
}

a {
  transition: $transition-base;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 $spacer;

  @include media-breakpoint-down(lg) {
    margin: 0 0 $spacer * 0.75;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// 404 page style
.amp_error_box {
  width: 500px;
  max-width: 100%;
  .amp_error_icon {
    width: 100%;
  }
}

// error page style
.amp_error_img {
  width: 300px;
  max-width: 100%;
}

// auth pages style
.amp_auth_box {
  max-width: 500px;
  width: 100%;
  box-shadow: 0px $spacer*0.25 $spacer*0.5 (-$spacer*0.125) rgba($black, 0.05);
}

// modal close
.amp_modal_close_wrap {
  position: sticky;
  top: 0;
  .amp_modal_close {
    width: $spacer*2.5;
    height: $spacer*2.5;
    padding: $spacer*0.6;
    border-radius: 0 0 0 50%;
    div {
      display: block;
      svg {
        display: block;
      }
    }
  }
}

.amp_logout_btn {
  padding: $spacer * 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
    width: $spacer * 1.125;
    height: $spacer * 1.125;
  }
}