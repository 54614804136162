@import 'scss/variables';

.amp_social_list {
  display: flex;
  column-gap: $spacer * 0.75;
  li {
    a {
      width: $spacer * 2;
      height: $spacer * 2;
      transition: $transition-base;
      background-color: $lighter;
      padding: $spacer * 0.5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray;
      div {
        width: 100%;
        height: 100%;
        svg {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
