@import 'scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

.amp_logo {
  width: 150px;
  max-width: 100%;
  height: auto;
  @include media-breakpoint-down(md) {
    width: 100px;
  }
}
