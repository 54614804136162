// colors
$primary: #E31E24 !default;
$secondary: #FFD500 !default;
$success: #0baa4a !default;
$danger: #f10000 !default;
$info: #17a2b8 !default;
$warning: #caa149 !default;
$light: #dddddd !default;
$lighter: #f2f2f2 !default;
$gray: #7B7B7B !default;
$dark: #333333 !default;
$black: #000000 !default;
$white: #ffffff !default;
$youtube: #ff0000 !default;
$facebook: #1877f2 !default;

// theme colors
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'info': $info,
  'warning': $warning,
  'light': $light,
  'lighter': $lighter,
  'gray': $gray,
  'dark': $dark,
  'black': $black,
  'white': $white,
) !default;
$min-contrast-ratio: 1 !default;

// Fonts
$font-family-base: 'Inter', sans-serif !default;

// font size
$font-size-base: 1rem !default;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-xs: $font-size-base * 0.75 !default;
$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.5 !default;
$h3-font-size: $font-size-base * 2 !default;
$h4-font-size: $font-size-base * 1.75 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$h6-font-size: $font-size-base * 1.125 !default;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-sm,
  8: $font-size-xs,
) !default;
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.25rem,
) !default;

// line height
$line-height-base: 1.6 !default;

// font weight
$font-weight-base: 400 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extrabold: 800 !default;
$headings-font-weight: $font-weight-bold !default;
$display-font-weight: $font-weight-light !default;

// spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 3.5,
  9: $spacer * 4,
  10: $spacer * 4.5,
  11: $spacer * 5,
  12: $spacer * 6,
) !default;
$enable-negative-margins: true !default;

// container width
$container-max-width: 1320px;
$container-md-max-width: 1096px;

// grid
$grid-columns: 12 !default;
$grid-gutter-width: 2rem !default;
$grid-row-columns: 6 !default;

// transition
$transition-base: all 0.25s ease-in-out;

// border radius
$border-radius: $spacer * 0.4 !default;
$border-radius-sm: $spacer * 0.2 !default;
$border-radius-lg: $spacer * 0.6 !default;
$border-radius-xl: $spacer !default;
$border-radius-pill: $spacer * 50 !default;

// shadow
$box-shadow: 0 0 1.25rem rgba($black, 0.13) !default;
$box-shadow-sm: 0 0 0.75rem -0.125rem rgba($black, 0.13) !default;
$box-shadow-lg: 0 0 2rem rgba($black, 0.13) !default;
$box-shadow-bottom: 0 0.6rem 0.75rem rgba($black, 0.05) !default;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1440px,
) !default;

// Forms
$input-padding-y-lg: $spacer * 1.1 !default;
$input-padding-x-lg: $spacer * 1.25 !default;
$input-color: $gray !default;
$input-border-color: $light !default;
$input-border-radius: $border-radius !default;
$input-box-shadow: none !default;
$form-select-indicator: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.1 8.6" fill="#010202"><path d="M8.1,8.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1L0.2,1C0.1,0.9,0.1,0.8,0,0.8c0-0.1,0-0.1,0-0.2 c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2C0.2,0.1,0.3,0.1,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0S0.9,0.1,1,0.2l7.1,7.1 l7.1-7.1C15.3,0.1,15.4,0,15.6,0s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4L8.5,8.5C8.4,8.5,8.3,8.5,8.3,8.6 C8.2,8.6,8.1,8.6,8.1,8.6z" /></svg>');
$form-check-input-width: $spacer * 1.5;
$form-check-padding-start: $form-check-input-width + 0.5rem !default;
$form-check-input-border: 2px solid $primary;
$form-check-label-cursor: pointer;

// input btn
$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1rem;
$input-btn-line-height: $line-height-base;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: $input-padding-y-lg;
$input-btn-padding-x-lg: $input-padding-x-lg;
$input-btn-font-size-lg: $font-size-base;

$btn-font-weight: $font-weight-semibold;
$btn-border-width: 2px;

// link
$link-color: $primary !default;
$link-hover-color: $gray !default;
$link-decoration: none !default;

// badge
$badge-border-radius: 0 !default;

// table
$table-cell-padding-y: $spacer * 0.5;
$table-cell-padding-x: $spacer;
$table-cell-padding-y-sm: $spacer * 0.25;
$table-cell-padding-x-sm: $spacer * 0.5;
$table-color: $dark;
$table-bg: $light;
$table-striped-color: $table-color;
$table-striped-bg-factor: 0.25;
$table-striped-bg: rgba($dark, $table-striped-bg-factor);
$table-accent-bg: rgba($primary, $table-striped-bg-factor);
$table-hover-color: $table-color;
$table-hover-bg-factor: 0.25;
$table-hover-bg: rgba($dark, $table-hover-bg-factor);
$table-border-width: 0;
$table-bg-scale: -90%;

// pagination
$pagination-font-size: $font-size-base * 0.75;

// breadcrumb
$breadcrumb-font-size: $font-size-sm;
$breadcrumb-divider-color: $secondary;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$breadcrumb-divider-color}'/></svg>");

// card
$card-title-color: $dark;
$card-border-width: null;
$card-border-color: null;
$card-border-radius: $border-radius-xl;
$card-box-shadow: null;

// modal
$modal-content-border-radius: $border-radius-xl;

// tooltip
$tooltip-color: $white;
$tooltip-bg: $dark;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;

// utilities
$utilities: (
  'font-weight': (
    property: font-weight,
    class: fw,
    values: (
      lighter: $font-weight-lighter,
      light: $font-weight-light,
      normal: $font-weight-normal,
      medium: $font-weight-medium,
      semibold: $font-weight-semibold,
      bold: $font-weight-bold,
      extrabold: $font-weight-extrabold,
    ),
  ),
  'rounded': (
    property: border-radius,
    class: rounded,
    values: (
      null: $border-radius,
      0: 0,
      1: $border-radius-sm,
      2: $border-radius,
      3: $border-radius-lg,
      4: $border-radius-xl,
      circle: 50%,
      pill: $border-radius-pill,
    ),
  ),
  'shadow': (
    property: box-shadow,
    class: shadow,
    values: (
      null: $box-shadow,
      sm: $box-shadow-sm,
      lg: $box-shadow-lg,
      bottom: $box-shadow-bottom,
      none: none,
    ),
  ),
) !default;
